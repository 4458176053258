<template>
    <multiselect
        id="unit_measurement"
        v-model="unitMeasurement"
        placeholder="Selecionar"
        :options="options"
        track-by="value"
        label="label"
        :allow-empty="allowEmpty"
        :showLabels="false"
        :showNoResults="false"
        class="with-border"
        :disabled="readonly"
        @select="onSelect"
        @remove="onSelect(null)"
    >
        <template slot="caret">
            <div class="chevron">
            <ChevronDown/>
            </div>
        </template>
        <template slot="singleLabel" slot-scope="{ option }">
            <span class="d-inline-block text-truncate width-90">
            {{ option.label }}
            </span>
        </template>
        <template slot="noOptions">
            Nenhuma opção
        </template>
        <template slot="noResult">
            Nenhum resultado
        </template>
    </multiselect>
</template>
<script>
import unitMeasures from '@itemOtherExpenseProps/utils/data/unitMeasures'

export default {
    components: {
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    },
    props: {
        value: {
            type: String,
            default: null
        },
        allowEmpty: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            unitMeasurement: null,
            options: unitMeasures
        }
    },
    methods: {
        onSelect(value) {
            this.$emit('input', value?.value)
            this.$emit('select', value?.value)
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (!value) {
                    this.unitMeasurement = null
                    return
                }
                this.unitMeasurement = this.options.find(option => option.value === value)
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>